<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="alarms"
      :loading="alarmStatus.loading"
      :items-per-page="50"
      class="elevation-1 fill-height"
    >
      <template v-slot:[`item.messageSent`]="{ item }">
        <v-chip :color="getColorFromBool(item.messageSent)" outlined>{{
          item.messageSent ? $t("common.sent") : $t("common.notSent")
        }}</v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-chip color="success" outlined @click="confirmAlarm(item)">
          <v-icon dark>mdi-check</v-icon>
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AlarmActive",
  data() {
    return {
      headers: [
        { text: this.$t("alarm.fields.id"), value: "alarmActiveId" },
        { text: this.$t("alarm.fields.name"), value: "alarm.name" },
        { text: this.$t("alarm.fields.message"), value: "alarm.message" },
        { text: this.$t("alarm.fields.messageSent"), value: "messageSent" },
        { text: this.$t("alarm.affirmation"), value: "action" },
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("alarms", { alarmStatus: "status", alarms: "activeAlarms" }),
  },

  methods: {
    ...mapActions("alarms", [
      "getActiveAlarms",
      "setCurrentAlarm",
      "setAlarmStatus",
    ]),

    getColorFromBool(bool) {
      return bool ? "success" : "error";
    },

    confirmAlarm(item) {
      this.setAlarmStatus(item.alarmActiveId);
    },
  },

  created() {
    this.getActiveAlarms();
  },
};
</script>

<style>
</style>